import {type DeserializeFn} from '@englex/slate-html-serializer';

import ToggleMark from './ToggleMark';
import {SlateMark, SlateObject} from '../../../interface';
import {buttonTitle} from './i18n';
import {ButtonType} from '../interface';
import {getElementCssStyleValue} from '../utils';
import RenderStrikeThrough from '../../../plugins/renderers/StrikeThrough';

class StrikeThrough extends ToggleMark {
  public type = ButtonType.STRIKE_THROUGH;
  public declare label?: undefined;
  public mark = SlateMark.STRIKE_THROUGH;
  public icon = 'strikethrough';
  public title = buttonTitle.StrikeThrough;
  public shortcut = 'mod+shift+x';

  public plugins = [new RenderStrikeThrough()];

  public fromHtml: DeserializeFn = (el: HTMLElement, next) => {
    let textDecoration = el.style && el.style.textDecoration; // for google docs

    if (!textDecoration && el.tagName.toLowerCase() === 's') {
      // from html and MSWord
      textDecoration = 'line-through';
    }

    if (!textDecoration && /^s\d+$/.test(el.className)) {
      // for RTF and Pages on macOS
      const selector = `${el.tagName.toLowerCase()}.${el.className}`;

      const propertyName = import.meta.env.MODE === 'test' ? 'text-decoration' : 'textDecoration';
      const decoration = getElementCssStyleValue(el, selector, propertyName);
      if (decoration) {
        textDecoration = decoration;
      }
    }

    if (textDecoration && textDecoration === 'line-through') {
      return {
        object: SlateObject.MARK,
        type: this.mark,
        nodes: next(el.childNodes)
      };
    }
    return;
  };
}

export default StrikeThrough;

import Color from './Color';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import RenderRed from '../../../../plugins/renderers/Color/Red';

export default class Red extends Color {
  public type = ButtonType.COLOR_RED;
  public title = buttonTitle.ColorRed;
  public className = 'red';

  public plugins = [new RenderRed()];
}

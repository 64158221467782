import Color from './Color';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import RenderOrange from '../../../../plugins/renderers/Color/Orange';

export default class Purple extends Color {
  public type = ButtonType.COLOR_PURPLE;
  public title = buttonTitle.ColorPurple;
  public className = 'purple';

  public plugins = [new RenderOrange()];
}

import Color from './Color';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import RenderOrange from '../../../../plugins/renderers/Color/Orange';

export default class Orange extends Color {
  public type = ButtonType.COLOR_ORANGE;
  public title = buttonTitle.ColorOrange;
  public className = 'orange';

  public plugins = [new RenderOrange()];
}

import {type Editor, type Mark} from '@englex/slate';
import {type Editor as ReactEditor} from '@englex/slate-react';

import ToggleMark from './ToggleMark';
import {type ClassMark, type SlateMark} from '../../../interface';
import {addMark, removeMark} from '../changes';
import {getActiveMarks} from '../queries';

const removeMarks = (editor: Editor, mark: SlateMark) => {
  editor.value.marks
    .filter((m: Mark) => m.type === mark)
    .forEach((m: Mark) => {
      editor.command(removeMark, m);
    });
};

abstract class ClassNameMark extends ToggleMark {
  public abstract className?: string;

  public isActive = (editor: Editor | ReactEditor) => this.hasClassNameMark(editor);

  protected toggleChange = (editor: Editor) => {
    const hasClassMark = this.hasClassNameMark(editor);
    editor.command(removeMarks, this.mark);
    if (!hasClassMark && this.className) {
      const {mark: type, className} = this;
      editor.command(addMark, {
        type,
        data: {
          className
        }
      });
    }
  };

  protected hasClassNameMark = (editor: Editor | ReactEditor) =>
    getActiveMarks(editor).some((mark: ClassMark) => {
      const className = mark.data.get('className');
      return !!className && mark.type === this.mark && className === this.className;
    });
}

export default ClassNameMark;

import Highlight from './Highlight';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import RenderOrange from '../../../../plugins/renderers/Highlight/Orange';

export default class Orange extends Highlight {
  public type = ButtonType.HIGHLIGHT_ORANGE;
  public title = buttonTitle.ColorOrange;
  public className = 'orange';

  public plugins = [new RenderOrange()];
}

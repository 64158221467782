import Color from './Color';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import RenderBlue from '../../../../plugins/renderers/Color/Blue';

export default class Blue extends Color {
  public type = ButtonType.COLOR_BLUE;
  public title = buttonTitle.ColorBlue;
  public className = 'blue';

  public plugins = [new RenderBlue()];
}

import React from 'react';
import {type TooltipProps} from 'rc-tooltip/lib/Tooltip';

import {type LinkInlineData} from '../../../../interface';
import LinkForm from './LinkForm';
import LinkPreview from './LinkPreview';
import ControlledTooltip from '../../components/ControlledTooltip';

interface LinkTooltipProps extends Partial<TooltipProps> {
  save: (data: LinkInlineData) => void;
  reset: (focus?: true) => void;
  getTooltipContainer?: () => HTMLElement;
  unlink?: () => void;
  href?: string;
  contentEditable?: false;
}

interface State {
  edit: boolean;
}

class LinkTooltip extends React.Component<LinkTooltipProps, State> {
  public state: State = {
    edit: false
  };
  private tooltip: ControlledTooltip | null;

  public render() {
    const {children, contentEditable, getTooltipContainer} = this.props;

    return (
      <ControlledTooltip
        ref={(e: ControlledTooltip) => (this.tooltip = e)}
        destroyTooltipOnHide={true}
        getTooltipContainer={getTooltipContainer}
        overlay={this.overlay()}
        placement="bottomLeft"
        prefixCls="slate-link-tooltip slate-nested-toolbar"
        defaultVisible={true}
        contentEditable={contentEditable}
      >
        {children}
      </ControlledTooltip>
    );
  }

  private toggleEdit = () => this.setState({edit: !this.state.edit});

  private overlay = () => {
    const {href, unlink} = this.props;
    if (this.isEdit) {
      return <LinkForm href={href} save={this.props.save} reset={this.reset} />;
    }

    return <LinkPreview edit={this.toggleEdit} href={href!} unlink={unlink!} />;
  };

  private get isEdit() {
    return !this.props.href || !this.props.unlink || this.state.edit;
  }

  private reset = (focus?: true) =>
    this.tooltip && this.tooltip.hide(() => this.props.reset(focus));
}

export default LinkTooltip;

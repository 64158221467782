import Highlight from './Highlight';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import RenderGreen from '../../../../plugins/renderers/Highlight/Green';

export default class Green extends Highlight {
  public type = ButtonType.HIGHLIGHT_GREEN;
  public title = buttonTitle.ColorGreen;
  public className = 'green';

  public plugins = [new RenderGreen()];
}

import Highlight from './Highlight';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import RenderRed from '../../../../plugins/renderers/Highlight/Red';

export default class Red extends Highlight {
  public type = ButtonType.HIGHLIGHT_RED;
  public title = buttonTitle.ColorRed;
  public className = 'red';

  public plugins = [new RenderRed()];
}

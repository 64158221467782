import {defineMessages} from 'react-intl';

const messages = defineMessages({
  placeholder: {
    id: 'SlateEditor.Forms.Link.Placeholder'
  },
  edit: {
    id: 'SlateEditor.Forms.Link.Submit.Edit'
  },
  add: {
    id: 'SlateEditor.Forms.Link.Submit.Add'
  },
  hrefPatternError: {
    id: 'SlateEditor.Forms.Link.Href.Validation.Pattern'
  },
  cancel: {
    id: 'SlateEditor.Forms.Link.Cancel'
  }
});

export default messages;

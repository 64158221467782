import Highlight from './Highlight';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import RenderYellow from '../../../../plugins/renderers/Highlight/Yellow';

export default class Yellow extends Highlight {
  public type = ButtonType.HIGHLIGHT_YELLOW;
  public title = buttonTitle.ColorYellow;
  public className = 'yellow';

  public plugins = [new RenderYellow()];
}

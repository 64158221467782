import Highlight from './Highlight';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import RenderBlue from '../../../../plugins/renderers/Highlight/Blue';

export default class Blue extends Highlight {
  public type = ButtonType.HIGHLIGHT_BLUE;
  public title = buttonTitle.ColorBlue;
  public className = 'blue';

  public plugins = [new RenderBlue()];
}

import React from 'react';
import {type Editor} from '@englex/slate';

import NestedToolbar from '../NestedToolbar';
import type Highlight from './Highlight';
import Orange from './Orange';
import Red from './Red';
import Blue from './Blue';
import Green from './Green';
import Yellow from './Yellow';
import {buttonTitle} from '../i18n';
import Icon from '../../../../../Icon';
import {type ClassMark, SlateMark} from '../../../../interface';
import {ButtonType} from '../../interface';
import {getActiveMarks} from '../../queries';

class HighlightToolbar extends NestedToolbar {
  public plugins: Highlight[] = [
    new Orange({toolbar: this}),
    new Yellow({toolbar: this}),
    new Red({toolbar: this}),
    new Blue({toolbar: this}),
    new Green({toolbar: this})
  ];

  public icon = 'virc-border-color';
  public title = buttonTitle.Highlight;
  public type = ButtonType.HIGHLIGHT;
  public vertical = false;

  public renderIcon = (editor: Editor) => {
    const mark = getActiveMarks(editor).find((m: ClassMark) => m.type === SlateMark.HIGHLIGHT);
    if (mark) {
      const plugin = this.plugins.find(
        (highlight: Highlight) => mark.type === highlight.mark && highlight.isActive(editor)
      );
      if (plugin) {
        return <Icon name={this.icon} className={plugin.className} />;
      }
    }
    return <Icon name={this.icon} />;
  };
}

export default HighlightToolbar;

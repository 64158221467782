import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import {type WrappedComponentProps, injectIntl} from 'react-intl';

import isShortcut from 'helpers/shortcut';

import Icon from '../../../../../Icon';
import messages from './i18n';

interface OwnProps {
  href: string;
  edit: () => void;
  unlink: () => void;
}

type Props = OwnProps & WrappedComponentProps;

class LinkPreview extends React.Component<Props> {
  public componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
  }

  public componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  public render() {
    const {intl, href} = this.props;
    return (
      <div className="slate-link-preview">
        <a href={href} target="_blank" className="lnk" title={href} rel="noopener noreferrer">
          {href}
        </a>
        <Button
          className="btn-transparent btn-sm btn-circle"
          onClick={this.edit}
          title={intl.formatMessage(messages.edit)}
        >
          <Icon name="pencil" />
        </Button>
        <Button
          className="btn-transparent btn-sm btn-circle"
          onMouseDown={this.unlink}
          title={intl.formatMessage(messages.cancel)}
        >
          <Icon name="unlink" />
        </Button>
      </div>
    );
  }

  private onKeyDown = (e: KeyboardEvent) => {
    if (isShortcut(e, 'mod+shift+k')) {
      this.props.edit();
    }
  };

  private edit = (e: React.MouseEvent<Button>) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.edit();
  };

  private unlink = (e: React.MouseEvent<Button>) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.unlink();
  };
}

export default injectIntl(LinkPreview);

import React from 'react';
import * as Yup from 'yup';
import InputGroupButton from 'react-bootstrap/lib/InputGroupButton';
import {Form, Formik, type FormikErrors} from 'formik';
import Button from 'react-bootstrap/lib/Button';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import {type WrappedComponentProps, injectIntl} from 'react-intl';
import Tooltip from 'rc-tooltip';
import {type FormikProps} from 'formik/dist/types';

import isShortcut from 'helpers/shortcut';

import {ButtonType} from '../../interface';
import Icon from '../../../../../Icon';
import messages from './i18n';
import {type LinkInlineData} from '../../../../interface';

import './LinkForm.scss';

interface OwnProps {
  save: (data: LinkInlineData) => void;
  reset?: (focus?: true) => void;
  href?: string;
}

type Props = OwnProps & WrappedComponentProps;

class LinkForm extends React.Component<Props, {}> {
  public type = ButtonType.LINK;
  private input: HTMLInputElement | null;

  public componentDidMount() {
    // autoFocus prop doesn't work somehow for add link, but does for edit
    // quick crutch fix for that
    setTimeout(() => this.input?.focus(), 120);
  }

  public componentWillUnmount() {
    if (this.props.reset) {
      this.props.reset();
    }
  }

  public render() {
    const {intl, href} = this.props;
    return (
      <Formik
        initialValues={{href: href || ''} as LinkInlineData}
        validationSchema={Yup.object().shape({
          href: Yup.string()
            .required(intl.formatMessage(messages.hrefPatternError))
            .url(intl.formatMessage(messages.hrefPatternError))
        })}
        onSubmit={this.submit}
      >
        {({values, errors, touched, handleChange, handleBlur}: FormikProps<LinkInlineData>) => (
          <Form className="link-edit">
            <FormGroup className={`href-field ${errors.href ? 'has-error' : ''}`}>
              <InputGroup bsSize="sm">
                <div className="input-error-tooltip">
                  <FormControl
                    autoComplete="off"
                    autoFocus={true}
                    bsSize="sm"
                    inputRef={el => (this.input = el)}
                    name="href"
                    onBlur={!!errors.href || !values.href.length ? this.disableBlur : handleBlur}
                    onChange={handleChange}
                    onKeyDown={this.onKeyDown}
                    placeholder={intl.formatMessage(messages.placeholder)}
                    type="text"
                    value={values.href}
                  />
                  {this.renderValidation(values, errors)}
                </div>
                <InputGroupButton>
                  <Button
                    bsSize="xs"
                    bsStyle="primary"
                    disabled={!!errors.href || !values.href.length}
                    type="submit"
                    title={intl.formatMessage(messages.add)}
                  >
                    <Icon name="save" />
                  </Button>
                </InputGroupButton>
              </InputGroup>
            </FormGroup>
          </Form>
        )}
      </Formik>
    );
  }

  private onKeyDown = (e: React.KeyboardEvent<FormControl>) => {
    if (this.props.reset && isShortcut(e.nativeEvent, 'esc')) {
      this.props.reset(true);
    }
  };

  private renderValidation = (values: LinkInlineData, errors: FormikErrors<LinkInlineData>) => {
    const icon = errors.href || values.href ? <Icon name="check-circle" /> : null;
    return Object.keys(errors).length ? (
      <Tooltip
        destroyTooltipOnHide={true}
        overlay={errors && errors.href ? errors.href : ''}
        trigger={['click', 'hover']}
        overlayClassName="rc-tooltip-input-error"
        placement="top"
      >
        <span className="input-error-tooltip-toggle">{icon}</span>
      </Tooltip>
    ) : (
      <span className="input-error-tooltip-toggle">{icon}</span>
    );
  };

  private disableBlur = (e: React.FormEvent<FormControl>) => {
    e.stopPropagation();
    e.preventDefault();
    this.input && this.input.focus();
  };

  private submit = (value: LinkInlineData) => {
    this.props.save(value);
  };
}

export default injectIntl(LinkForm);

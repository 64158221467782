import React from 'react';
import {type Editor} from '@englex/slate';

import NestedToolbar from '../NestedToolbar';
import type Color from './Color';
import Orange from './Orange';
import Red from './Red';
import Blue from './Blue';
import Green from './Green';
import {buttonTitle} from '../i18n';
import {ButtonType} from '../../interface';
import Icon from '../../../../../Icon';
import {type ClassMark, SlateMark} from '../../../../interface';
import {getActiveMarks} from '../../queries';
import Purple from './Purple';

class FontColorToolbar extends NestedToolbar {
  public plugins: Color[] = [
    new Orange({toolbar: this}),
    new Red({toolbar: this}),
    new Blue({toolbar: this}),
    new Green({toolbar: this}),
    new Purple({toolbar: this})
  ];

  public icon = 'virc-text-color';
  public title = buttonTitle.Color;
  public type = ButtonType.COLOR;
  public vertical = false;

  public renderIcon = (editor: Editor) => {
    const mark = getActiveMarks(editor).find((m: ClassMark) => m.type === SlateMark.COLOR);
    if (mark) {
      const plugin = this.plugins.find(
        (fontColor: Color) => mark.type === fontColor.mark && fontColor.isActive(editor)
      );
      if (plugin) {
        return <Icon name={this.icon} className={plugin.className} />;
      }
    }
    return <Icon name={this.icon} />;
  };
}

export default FontColorToolbar;

import React from 'react';
import {type Editor} from '@englex/slate';

import Icon from '../../../../../Icon';
import ClassNameMark from '../ClassNameMark';
import {SlateMark} from '../../../../interface';

import './Color.scss';

abstract class Color extends ClassNameMark {
  public mark = SlateMark.COLOR;
  public iconActive = 'times-circle';
  public icon = 'circle';

  protected renderIcon = (editor: Editor): React.ReactNode => {
    const icon = this.getIcon(editor);
    return icon ? <Icon name={icon} className={this.className} /> : null;
  };
}

export default Color;
